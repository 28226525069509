import React from 'react'

import Typist from '@components/common/typist'
import * as Icons from '@components/scrollytelling/reports/infographic-icons'
import Link from '@components/common/link'
import { motion, AnimatePresence } from 'framer-motion';
import Icon from '@components/common/icons';

const ChapterHeader = ({ title, textShadow, quote }) => {
  return (
    <div className={'px-5 flow flex flex-col justify-center items-center'}>
      <h1
        style={{ textShadow: textShadow ? '2px 2px 15px rgba(0,0,0,0.6)' : 'none' }}
        className={'text-white text-center md:text-7xl lg:text-8xl xl:text-9xl'}
      >
        {title}
      </h1>
      <h2 className={'z-50 px-3 py-2 inline-block mx-auto text-white bg-burgundy text-center text-xl sm:text-5xl'} style={{ marginTop: '2.8rem' }}>
        <Typist texts={quote} />
      </h2>
    </div>
  )
}

const InlineCheckList = ({ children, collapsible = false, isOpen = false, openText = 'Show more', closeText = 'Show less' }) => {
  const childrenArray = React.Children.toArray(children);
  const [collapsed, setCollapsed] = React.useState(!isOpen);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  if (!collapsible) {
    return (
      <div className="py-5">
        <ul className="pt-5 checked-box">
          {childrenArray.map((child, index) => (
            <li key={index} className="pb-5">{child}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="py-5" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
      <button
        onClick={toggleCollapse}
        className={`${!collapsed ? 'bg-gray-200' : 'bg-burgundy text-white'} px-5 py-3 font-bold flex items-center`}
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: collapsed ? 0 : 90, translateX: collapsed ? 0 : -3, translateY: collapsed ? 0 : 4 }}
          transition={{ duration: 0.3 }}
          style={{ transformOrigin: 'center' }}
        >
          <Icon icon="chevron-right" className="w-auto h-8 inline -ml-2 mr-2" />
        </motion.div>
        {collapsed ? openText : closeText}
      </button>
      <ul className={`pt-10 checked-box ${collapsed ? 'hidden' : ''}`} style={{ listStyleType: 'disc' }}>
        {childrenArray.map((child, index) => (
          <li key={index} className="pb-5">{child}</li>
        ))}
      </ul>
    </div>
  );
};

const InlineList = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <ul className={'py-5'}>
      {childrenArray.map((child) => (<li className={'pb-3'} key={`list-el${child}`}>{child}</li>)
      )}
    </ul>
  )

}

const InlineTitle = ({ text }) => {
  return (
    <h1 className={'text-3xl sm:text-4xl xl:text-4xl'}>{text}</h1>
  )
}

// const InlineYoutubeVideo = ({ caption, src }) => {
//   return (
//     <div className={'relative h-0 pt-sixteenbynine mx-auto py-12 w-full mb-20'}>
//       <iframe
//         className={'absolute top-0 left-0 w-full h-full'}
//         width='100%'
//         height='auto'
//         src={src}
//         title='YouTube video player'
//         frameborder='0'
//         allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
//         allowFullScreen
//       />
//       {caption && (
//         <p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4 mt-12'}>
//           {caption}
//         </p>
//       )}
//     </div>
//   )
// }

const InlineImage = ({ caption, fileName, wide }) => {
  return (
    <div className={`py-12 ${wide ? 'sm:mx-0 md:-mx-52 lg:-mx-84 xl:-mx-96' : 'sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}`}>
      <img src={`/images/reports/annual-report-2023/${fileName}`} />
      {caption && (
        <p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
          {caption}
        </p>
      )}
    </div>
  )
}

const InlineTypist = ({ text, textAlign }) => {
  return (
    <h1 className={`z-50 w-full inline-block mx-auto text-burgundy text-center text-2xl sm:text-5xl mb-8 ${textAlign || 'text-center'}`}>
      {/* {text.length > 1 ? text[0] : text} */}
      <Typist texts={text} />
    </h1>
  )
}

const InlineQuote = ({ person, text }) => {
  return (
    <>
      <blockquote style={{ marginTop: '3rem', marginBottom: '3rem' }} className={'text-2xl sm:text-4xl sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
        {text} <span className={'block pt-4 text-gray-700 text-3xl font-sans text-left'}>– {person}</span>
      </blockquote>
    </>
  )
}

const MarginBreakContainer = ({ children }) => {
  return <div className={'py-16 margin-break mx-auto'}>{children}</div>
}

// const BlockImage = ({ fileName }) => {
// 	return (
// 		<div>
// 			<img src={`/images/reports/annual-report-2022/${fileName}`} />
// 		</div>
// 	)
// }

// const InlineAudio = ({ externalLink, externalLinkText, fileName, type }) => {
//   return (
//     <div className={'py-12 w-full mx-auto text-center'}>
//       <audio controls className={'w-full mb-8'}>
//         <source src={require('../../audio/reports/annual-report-2021/' + fileName).default} type={type || 'audio/mp3'} />
//         Your browser does not support the audio element.
//       </audio>
//       <div className={'mx-auto'}>
//         <Link href={require('../../audio/reports/annual-report-2021/' + fileName).default}>Download audio file</Link>
//         {externalLinkText && externalLink && (
//           <>
//             <span className={'px-3'}>or</span>
//             <Link href={externalLink}>
//               <span>{externalLinkText}</span>
//             </Link>
//           </>
//         )}
//       </div>
//     </div>
//   )
// }

// const InlineVideo = ({ caption, fileName, autoPlay, loop }) => {
//   return (
//     <div className={'sm:mx-0 md:-mx-10 lg:-mx-72 xl:-mx-96 py-12'}>
//       <video autoPlay={autoPlay || true} muted={autoPlay || muted || false} loop={loop || true} playsInline>
//         <source src={`/images/reports/annual-report-2021/${fileName}`} />
//       </video>
//       {caption && (
//         <p style={{ background: '#f2f2f2', color: '#63666a' }} className={'text-gray-600 p-4'}>
//           {caption}
//         </p>
//       )}
//     </div>
//   )
// }

const CHAPTERS = [
  {
    title: 'Welcome',
    id: 'welcome',
    backgroundImage: '01-welcome',
    className: 'bg-gray-300',
    htmlContent: <ChapterHeader title={'Welcome'} subtitle={'Here’s what we achieved.'} quote={['Here’s what we achieved.']} />,
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTypist text={['Here’s what we achieved.']} />

        <p>
          In 2022, The New Humanitarian (TNH) launched a new five-year strategy to build a transformative newsroom for our times. In 2023, the second year of its execution, we hired our first Senior Editor for Inclusive Storytelling, launched a Humanitarian Storytelling Fellowship for journalists from the Global South, and changed the makeup of our newsroom to reflect our commitment to decolonisation. For our journalism this year we were honoured with the prestigious Harvard Humanitarian Initiative’s Elisabeth B. Weintz Humanitarian Award.
        </p>

        <h2 className={'text-center w-full'}>2023 at a glance</h2>

        <MarginBreakContainer>
          <div className={'gap-x-10 gap-y-14 grid grid-cols-1 sm:grid-cols-3 justify-center items-start sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Clipboard />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>444</span>published reports
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Star />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>8</span> investigations
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Trophy />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>5</span> awards and nominations
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Newspaper />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>175,182</span> average monthly users
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.SocialMedia />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>269,562</span> social media followers
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Youtube />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>601,582</span> views on YouTube
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Podcast />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>162,857</span> podcast downloads
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Group />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>469</span>members
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Globe />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>105</span>territories covered
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Handshake />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>22</span>donors
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Staff />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>10</span> new hires
              </h3>
            </div>
            <div className={'flex items-center justify-center flex-col text-center'}>
              <div className={'text-burgundy w-32'}>
                <Icons.Microphone />
              </div>
              <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                <span className={'font-bold font-sans block text-burgundy'}>46</span>speaking engagements
              </h3>
            </div>
          </div>
        </MarginBreakContainer>

        <h2>
          Our other progress included:
        </h2>

        <ul className={'mb-8'}>
          <li className={'pb-3'}><p>Adopting an impact-first mindset</p></li>
          <li className={'pb-3'}><p>Building out our events</p></li>
          <li className={'pb-3'}><p>Creating a tool for other media to republish our work</p></li>
          <li className={'pb-3'}><p>Launching a new policy-focused newsletter, Inklings</p></li>
          <li className={'pb-3'}><p>Increasing our output by 30 percent and doubling our investigations</p></li>
          <li className={'pb-3'}><p>Developing a Duty of Care framework for staff and collaborators</p></li>
          <li className={'pb-3'}><p>Attracting four new donors and hosting our first ever in-person TNH donor convening</p></li>
          <li className={'pb-3'}><p>Strengthening our leadership effectiveness through staff consultations, workshops and coaching</p></li>
        </ul>

        <InlineQuote
          text='“I rely on TNH so heavily to bring me news from perspectives I can’t find anywhere else, and I feel that the stories you are telling are the DNA of peacebuilding. I am just so tremendously grateful for your commitment, and the energy and power you have brought to journalism, and also to “shifting the power” during such a turbulent time in the world.”'
          person='Melanie Greenberg, Managing Director, Humanity United'
        />
      </div>
    ),
  },
  {
    title: 'Foreword',
    id: 'foreword',
    backgroundImage: '02-foreword',
    className: 'bg-gray-200',
    htmlContent: <ChapterHeader textShadow title={'Foreword'} subtitle={'Rethinking news.'} quote={['Rethinking news.']} />,
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTitle text={'Page through this 2023 Annual Report and you will notice that people at the heart of humanitarian crises take centre stage. Their stories are key as we challenge ourselves to rethink who makes news and how they make news.'} />

        <p>This approach has become even more critical this year. The different ways the largely Western Based international media covered events in Ukraine and Gaza highlights the need for change. As our colleague Patrick Gathara pointed out in the Nieman Journalism Lab, dehumanising portrayals have long been a feature of how the global press presents much of the world, especially communities in crisis and in the Global South.</p>

        <p>We took a fact-based stance against these dangerous double standards in media coverage in an Editorial – Media coverage of Israel and Gaza is rife with deadly double standards – that became our most read article of 2023. Our coverage of Gaza – from the early days – was distinct in this regard, instilled with values of humanity.</p>

        <p>
          The challenges of covering global humanitarian crises have made ever more clear the need for media that see their audiences as truly universal; that treat all lives as having equal value; and that are not aligned – consciously or not – with any national or geopolitical agendas.
        </p>

        <p>
          This year we continued to shine a light on neglected crises around the world, from gang violence in Central America and Haiti to Nagorno-Karabakh’s already forgotten refugees; from violence in the Sahel to the languishing state of migrants, both in Europe and on SouthSouth migration paths; from the conflicts in the Central African Republic and the Democratic Republic of the Congo to Yemen and Myanmar.
        </p>

        <p>
          For The New Humanitarian, 2023 was a year of changing the very make-up of our newsroom. We are decolonising our journalism, changing how we think and report on complex issues while remaining fact-based and on the ground. Our journalism may grow and be written alongside our cosmopolitan outlook, but we remain grounded in stories told by those affected, by those living and working in the communities that we report on.
        </p>

        <p>
          This is an ongoing challenge. It calls for introspection and an ability to challenge our own biases.
        </p>

        <p>
          This year, we flipped the narrative in an ongoing series that puts the voices of refugees, asylum seekers and migrants at the centre of conversations about the policies and events that shape their lives, and whipped up a new podcast on What’s Unsaid in the sector that platforms critical, local voices frequently missing in decision-making processes. Through The Yemen Listening Project, more than 100 Yemenis – from inside the country and across the world – lifted the curtain on what it means to be a human being in the midst of a humanitarian crisis, not just experiencing loss, violence and exile, but also tales of love, family connection, and personal and professional persistence in the face of impossible-seeming obstacles.
        </p>

        <p>
          We leveraged the power of different mediums that invite creativity and freedom from our contributors: poems, illustrations, and first-person accounts. Recognising the need to give local reporters the tools to disrupt the Western gaze and tell local stories to globalised audiences, we launched the Humanitarian Reporting Fellowship, which embeds local journalists within our newsroom. Telling stories through a local or universal lens, speaking to local and global audiences is how we can help change a system that remains Western-centric and deeply rooted in colonial legacies.
        </p>

        <p>
          The whole sector of aid is changing, and so are we. As one of the few newsrooms putting journalism at the service of people affected by humanitarian crises around the world, we are committed to using our power for good, even when things get uncomfortable.
        </p>

        <p>
          Decolonisation is not just a buzz word for us; it is an intentional, vital, and necessary process if we are to fulfil the vision of a transformative, forward-looking newsroom for these times.
        </p>

        <div className={'grid grid-cols-1 gap-x-16 sm:grid-cols-2'}>
          <div className={'flex flex-col'}>
            <div
              className={'w-72 h-96'}
              style={{ background: 'url(/images/reports/annual-report-2023/paula-fray.png)', backgroundSize: 'cover' }}
            />
            <p className="pt-2">
              <strong>Paula Fray</strong>
              <br />
              President
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Strategy',
    id: 'strategy',
    backgroundImage: '03-strategy',
    className: 'bg-gray-300',
    htmlContent: (
      <ChapterHeader textShadow title={'Strategy'} subtitle={'Becoming a transformative newsroom.'} quote={['Becoming a transformative newsroom. ']} />
    ),
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTypist text={['Becoming a transformative newsroom..']} />

        <p>
          At TNH, we believe it can be transformative to challenge the power structures within journalism, especially how stories are told and who sets the narrative. We believe it can be transformative to intentionally pursue impact as central to the production of our journalism. For us, putting our journalism at the service of the millions of people affected by humanitarian crises means we must embrace transformative concepts of journalism, impact, sustainability and culture. We aim for each of our stories to find an audience that can use it to drive positive social change.
        </p>

        <p>
          In the second year of our <Link href="https://interactive.thenewhumanitarian.org/reports/2022/12/05/our-strategy/">five-year strategy</Link>, we continued to advance on our goals in having achieved the following:
        </p>

        <h2>Decolonising our journalism:</h2>

        <p>We have made significant strides toward our first strategic priority to decolonise our journalism by becoming more inclusive and following the guidance of the communities we serve, aiming to better represent the issues that matter to them.</p>

        <InlineCheckList collapsible openText={'In 2023:'} closeText={'Close'}>
          <p><strong>Nearly half</strong> of our contributors were nationals of the countries they reported from, and 54 percent of our podcast guests and event speakers were from the Global South.</p>
          <p>More than <strong>80 percent</strong> of our country-specific articles included at least one local voice, while nearly half of our global stories featured voices from the Global South.</p>
          <p>We hired <strong>five</strong> new editorial staff with ties to the Global South, moving towards our goal of having at least 60 percent of our journalists connected to the Global South by 2026.</p>
          <p>Our Humanitarian Storytelling Fellowship program introduced new voices, including our inaugural fellow, Zubaida Baba Ibrahim, a Nigerian journalist and creative writer.</p>
          <p>We continued to challenge traditional narratives of power and accountability by shifting the tone, imagery, and framing of our articles, and acknowledging colonial histories and long-accepted power disparities.</p>
          <p>We launched a new monthly column, <Link href="https://www.thenewhumanitarian.org/tags/decolonise-how">Decolonise How?</Link>, by our Senior Editor for Inclusive Storytelling, Patrick Gathara</p>
          <p><strong>More than half</strong> of our content had an accountability angle, with a focus on solutions-based coverage.</p>
          <p>We experimented with <Link href="https://interactive.thenewhumanitarian.org/stories/2023/11/28/art-time-crisis-drawing-derna/">visual storytelling</Link>, <Link href="https://www.thenewhumanitarian.org/podcast/whats-unsaid">audio formats</Link>, and <Link href="https://www.thenewhumanitarian.org/ar/news-feature/2023/10/25/kyf-tdm-shbkat-almsadt-almtbadlt-alastjabt-alansanyt-fy-alswdan">local language translations</Link> to make our content more accessible to the communities we report on.</p>
          <p>We published a collection of Libyan artists’ <Link href="https://www.thenewhumanitarian.org/photo-feature/2023/10/05/snapshots-libyan-photographer-captures-life-after-libya-floods">interpretations</Link> of the deadly floods in Libya and launched the <Link href="https://yemenlisteningproject.thenewhumanitarian.org/en/grid">Yemen Listening Project</Link> for participatory journalism.</p>
        </InlineCheckList>

        <h2>Centering impact in our work:</h2>
        <p>
          Our second strategic priority is to centre impact in our work by proactively mapping and reaching out to the audiences that can use our stories to drive change.
        </p>

        <InlineCheckList collapsible openText={'In 2023:'} closeText={'Close'}>
          <p>We adopted an impact-first approach for commissioning stories. Pitch sheets now prompt contributors and editors to assess the potential impact of their work, and we have new tools to update our impact dashboard every month.</p>
          <p>Our <Link href="https://www.thenewhumanitarian.org/news-feature/2023/08/02/how-mutual-aid-networks-are-powering-sudans-humanitarian-response">reporting</Link> on mutual aid networks in Sudan, in collaboration with <Link href="https://3ayin.com/en/">Ayin Media</Link>, was translated into <Link href="https://www.thenewhumanitarian.org/fr/news-feature/2023/10/25/comment-les-reseaux-dentraide-contribuent-laction-humanitaire-au-soudan">French</Link> and <Link href="https://www.thenewhumanitarian.org/ar/news-feature/2023/10/25/kyf-tdm-shbkat-almsadt-almtbadlt-alastjabt-alansanyt-fy-alswdan">Arabic</Link>. This showed our audience how community resources and local knowledge deliver aid and support services, highlighting mutual aid in Sudan.</p>
          <p>We significantly expanded our <Link href="https://www.thenewhumanitarian.org/events">events</Link> portfolio, increasing from three events in 2022 to <strong>11 in 2023</strong>. This allowed us to reach new audiences, including policymakers and affected communities, facilitating direct dialogue and asserting our position on decolonisation issues within the media and humanitarian sectors.</p>
          <p>We saw a return to growth in overall website traffic, with 175,182 average monthly users. Our commitment to producing content tailored for social media platforms resulted in significant audience growth, with a <strong>12 percent</strong> increase in <Link href="https://www.thenewhumanitarian.org/podcast">podcast</Link> downloads and an <strong>11 percent</strong> increase in social media followers, amassing approximately 269,000 followers across various platforms.</p>
          <p>Our policy-focused newsletter, <Link href="https://www.thenewhumanitarian.org/newsletter">Inklings</Link>, led to a <strong>10 percent</strong> increase in newsletter sign-ups, with 1,862 new subscribers.</p>
        </InlineCheckList>

        <h2>Strengthening our newsroom:</h2>

        <p>Our third strategic priority is strengthening our newsroom by investing in key editorial functions and necessary logistical, legal and administrative support so that we can deliver on our mission.</p>

        <InlineCheckList collapsible openText={'In 2023:'} closeText={'Close'}>
          <p>We <strong>invested</strong> in key editorial functions and support systems to consistently report from the heart of crises.</p>
          <p>We grew our <Link href="https://www.thenewhumanitarian.org/aid-and-policy">aid policy coverage</Link> by <strong>15 percent</strong>, hired an additional policy reporter/editor, and launched Inklings, our policy-focused newsletter.</p>
          <p>We commissioned <strong>33 percent</strong> more stories from freelance contributors, and a new commissioning process workflow introduced in 2022 enabled our editorial team to function more efficiently and effectively.</p>
          <p>We also strengthened our newsroom by <strong>creating and filling four new roles:</strong> Senior Editor for Inclusive Storytelling, Social and Breaking News Editor, a second Policy Editor, and a second Podcast Producer.</p>
          <p>We improved our <strong>approaches to risk and crisis management</strong> by conducting crisis management training, revising our Standard Operating Procedures (SOPs), and launching TNH 360, our duty of care framework for supporting staff and freelance contributors before, during, and after high-risk assignments.</p>
        </InlineCheckList>

        <h2>Building a more sustainable business model:</h2>

        <p>
          The fourth strategic priority involves building a more sustainable newsroom model by growing grant funding and developing new revenue streams so that our journalism remains independent and viable in the long-term.
        </p>

        <InlineCheckList collapsible openText={'In 2023:'} closeText={'Close'}>
          <p>We <strong>committed</strong> to growing and diversifying our income by increasing grant funding and developing new revenue streams to ensure our journalism remains independent and sustainable.</p>
          <p>We <strong>increased</strong> our income to CHF 3.6 million, a 20 percent rise from 2022. </p>
          <p>We attracted <strong>four</strong> new institutional donors (Ford Foundation, Hans Wilsdorf Foundation, Luminate, and Irish Aid), received <strong>two</strong> small grants from high-net-worth individuals, and renewed <strong>five</strong> grants.</p>
          <p>We secured an additional CHF 500,000 through increased grant sizes from our existing donors, demonstrating a sustained and positive engagement with our partners. </p>
          <p>We hosted our <strong>first in-person donor convening</strong> to strengthen relationships and deepen donor understanding of our mission and impact.</p>
          <p>We <strong>incorporated TNH-US</strong>, a new legal entity aimed at raising money and visibility for TNH in the United States, which is currently awaiting charitable status approval from the US Internal Revenue Service.</p>
        </InlineCheckList>

        <h2>Nurturing an organisational culture of excitement, innovation and well-being:</h2>

        <p>Our fifth strategic priority seeks to nurture an organisational culture that is based on excitement, innovation and well-being by better understanding and responding to the needs of our staff, improving benefits, and creating space for creativity so that staff feel valued, proud and motivated to do their best work.</p>

        <InlineCheckList collapsible openText={'In 2023:'} closeText={'Close'}>
          <p>We ensured that staff had multiple opportunities to collaborate and build trust in person by holding <strong>three retreats</strong>: one for all staff, one for the editorial team, and one for the operations team.</p>
          <p>We saw positive adoption of our organisational policies, including an <strong>increase</strong> in the use of leave available to staff. </p>
          <p>We <strong>launched</strong> a psycho-social support service to support both staff and freelance journalists in their work.</p>
          <p>We crafted and implemented our <strong>four organisational values</strong> of courage, accountability, humility, and solidarity. </p>
          <p>We also created a <strong>TNH Values Committee</strong>, a dedicated cross-functional team fostering a values-driven culture throughout the organisation.</p>
        </InlineCheckList>
      </div>
    ),
  },
  {
    title: 'Our journalism',
    id: 'our-journalism',
    backgroundImage: '04-our-journalism',
    className: 'bg-gray-300',
    htmlContent: <ChapterHeader title={'Our journalism'} subtitle={'From the heart of crises.'} quote={['From the heart of crises.']} />,
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTitle text={'We tell local stories globally, with integrity, authenticity, and authority. We blend award-winning journalism, analysis, and data to give decision-makers, influencers, and anyone interested in or affected by crises a real-time, in-depth view of the realities on the ground and the policy debates surrounding them.'} />

        <InlineImage fileName={'our-journalism--map.jpg'} caption={'Places we reported from in 2023.'} wide />

        <h2>Calling for accountability: from governments to aid organisations</h2>

        <p>
          Accountability is a focal point that runs through all our coverage, part of our effort to provide reporting that pushes toward next steps: who holds responsibility for the situation at hand, who holds the power to push for positive change? Three exclusive investigative pieces in 2023 offered especially strong accountability angles:
        </p>

        <InlineList>
          <p><Link href="https://www.thenewhumanitarian.org/investigations/2023/07/03/exclusive-civilians-targeted-nigerias-war-boko">Reporting</Link> on civilians who were targeted in Nigeria’s war on Boko Haram.</p>
          <p><Link href="https://www.thenewhumanitarian.org/investigations/2023/03/08/exclusive-who-sex-abuse-victims-say-help-too-little-too-late">Interviews</Link> with sex abuse victims of WHO staff who said that aid was “too little and too late.”</p>
          <p><Link href="https://www.thenewhumanitarian.org/investigations/2023/06/05/exclusive-givedirectly-loses-900000-drc-mobile-cash-fraud">The discovery</Link> that almost $1 million in cash was lost to fraud in the DRC.  </p>
        </InlineList>

        <h2>Calling out double standards</h2>

        <p>Across our reporting, we called out double standards in aid policy and practice, and we used language and narrative to shape perceptions and stir change across myriad crises. Some instances in which we pushed especially hard to name double standards are:</p>

        <InlineList>
          <p>Our panel discussion ‘Everybody’s hiding their skeletons’: a gloves-off conversation on aid diversion and double standards where aid leaders described how local organisations are <Link href={'https://www.thenewhumanitarian.org/news-feature/2023/09/25/rethinking-aid-financing-locally-led-organisations-funding'}>taking matters into their own hands</Link>.</p>
          <p>Our <Link href="https://www.thenewhumanitarian.org/news/2023/05/03/un-staff-letter-women-taliban-ban-approach">report</Link> on a UN staff letter leaked to us blasting the ‘incoherent’ approach to a Taliban ban on women.</p>
          <p>TNH’s <Link href="https://www.thenewhumanitarian.org/news-feature/2023/05/31/how-racial-profiling-within-eus-free-travel-zone-harms-asylum-seekers-and">feature</Link> on reintroduced Schengen Zone border controls and racial profiling.</p>
          <p>A <Link href="https://www.thenewhumanitarian.org/analysis/2023/03/29/drc-ebola-un-sex-abuse-victims-justice">companion piece</Link> to our exclusive <Link href="https://www.thenewhumanitarian.org/investigations/2023/03/08/exclusive-who-sex-abuse-victims-say-help-too-little-too-late">investigation</Link> into compensation for Ebola sex abuse victims in which one source explained how “the UN has essentially created a two-tier system for survivors of sexual abuse.”</p>
          <p>Our first-ever poem, <Link href="https://www.thenewhumanitarian.org/opinion/first-person/2023/05/29/lyric-flooded-colonised-and-coloniser">Lyric for the flooded</Link>, colonised and coloniser, shared Kenyan poet Shaijla Patel’s reflections on flooding in Somalia and in its former coloniser, Italy.</p>
        </InlineList>

        <h2>Calling attention: neglected and emerging crises</h2>

        <p>In a year that was the deadliest for civilians in nearly two decades, TNH continued to highlight neglected and emerging crises. While Gaza, and to some extent Ukraine, continued to make headlines in mainstream and Western media, we sought to call attention to the ballooning crises in Sudan and Haiti and the DRC; ongoing crises in Afghanistan and Myanmar; emerging crises in Niger and in much of Latin America, where gang violence is spreading. </p>

        <p>Often, our reporting noted overlapping crises, where conflict was exacerbated by climate change, for instance, and the outsize toll of all conflicts on women and children. Our reporting is old-fashioned, on-the-ground reporting, working with local journalists to speak with locals; digging up data to fact-check claims; going into deep 360-degree reporting to represent the perspectives of all parties involved. A few of our key reports include:</p>

        <InlineList>
          <p><Link href="https://www.thenewhumanitarian.org/photo-feature/2023/03/13/displaced-congolese-m23-conflict-peace">'I just want to return home': displaced Congolese on the M23 conflict and the need for peace.</Link></p>
          <p><Link href="https://www.thenewhumanitarian.org/news-feature/2023/04/26/somalia-drought-toll">'I poison my children in order to survive': the terrible toll of Somalia’s drought.</Link></p>
          <p><Link href="https://www.thenewhumanitarian.org/analysis/2023/05/17/gang-violence-latin-america-challenge-aid-sector">Surging gang violence across Latin America challenges aid sector to respond.</Link></p>
          <p>Our Snapshots series, which highlights the experience of journalists in inaccessible locations and situations, such as the <Link href="https://www.thenewhumanitarian.org/photo-feature/2023/08/07/six-months-syrian-photographers-capture-life-after-quakes">Syria earthquakes</Link> or from within the <Link href="https://www.thenewhumanitarian.org/photo-feature/2023/07/10/snapshots-palestinian-photographer-captures-life-gaza">Gaza bombardment</Link>.</p>
        </InlineList>

        <InlineImage fileName={'our-strategy--inline.jpg'} caption={'Consisting of 11 floors and 85 apartments in the al-Nasr area of Gaza city, residents say it is a purely civilian structure. (Mohammed Zaanoun/TNH)'} />

        <h2>Calling for a rethink: neutrality, aid freezes, and top-down decision making</h2>

        <p>From new conflicts in Gaza and Sudan, to flood disasters in Libya and East Africa, to earthquakes in Morocco, Syria, and Türkiye, humanitarian crises around the world drove more than 350 million people to seek aid in 2023. While funding to address those needs reached record levels, so did the funding gap. </p>

        <p>Yet as CEO Heba Aly noted on the Rethinking Humanitarian podcast, the year also brought bold and ambitious ideas for how to enable countries to deal with crises when they hit. Questions about neutrality, and humanitarian principles, were also part of the conversation. Throughout our aid policy coverage, we have pushed to reimagine the sector and find solutions to the ongoing problem of needs outpacing funding. We also pushed to connect people to policies, making clear that the decisions made in Geneva or New York shape lives halfway around the world. Our reporting in 2023 included:</p>

        <InlineList>
          <p>Our <Link href="https://www.thenewhumanitarian.org/news/2023/06/22/usaidwfp-food-aid-freeze-leads-suffering-and-deaths-tigray">exclusive reporting</Link> on the USAID/WFP food aid freeze leading to suffering and deaths in Tigray.</p>
          <p>Our <Link href="https://www.thenewhumanitarian.org/analysis/2023/05/16/ukraine-debates-humanitarian-neutrality-debates">analysis</Link> on why Ukraine is moving the needle on old debates about humanitarian neutrality.</p>
          <p>Our <Link href="https://www.thenewhumanitarian.org/analysis/2023/04/05/whats-flagship-initiative-emergency-aid">report</Link> on the ‘Flagship Initiative’, a plan to shake up the UN aid delivery system, and how it might transform emergency aid.</p>
          <p><Link href="https://www.thenewhumanitarian.org/analysis/2023/05/23/how-vague-money-laundering-and-counter-terror-rules-slow-aid">A look</Link> at how vague money-laundering and counter-terror rules slow aid.</p>
        </InlineList>

        <h2>Calls for action: humanitarian resistance, continued aid for Syria, and flipping the narrative on migration</h2>

        <p>Our opinion and first-person journalism create a space for new ideas and calls for action from across the aid sector and beyond. Through op-eds, personal essays and Q&A interviews and podcasts, we offer a variety of perspectives that reflect on the issues covered in our fact-based, firsthand reporting. Here’s a glimpse of our 2023 opinion journalism: </p>

        <InlineList>
          <p>A longtime aid professional called for concerted outrage in Myanmar, Gaza, and why it’s time for humanitarian resistance, highlighting the divergent international response to the two crises. </p>
          <p>The director of Syrian Civil Defence (The White Helmets) called for a move away from the requirement that the UN Security Council approve aid shipments to Syria every six months in Is it too much to ask that Syrians receive the aid they need?</p>
          <p>In our series Flipping the Narrative refugees, asylum seekers, and migrants addressed policies and events that have shaped their lives through personal essays such as Flipping the Narrative: how an ID card shapes a refugee’s life.</p>
        </InlineList>

      </div>
    ),
  },
  {
    title: 'Our impact',
    id: 'our-impact',
    backgroundImage: '05-our-impact',
    className: 'bg-gray-200',
    htmlContent: (
      <ChapterHeader
        textShadow
        title={'Our impact'}
        subtitle={'How our journalism creates real impact.'}
        quote={['How our journalism creates real impact.']}
      />
    ),
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTypist text={['How our journalism creates real impact.']} />

        <InlineTitle text="TNH’s journalism contributes to more effective, accountable and inclusive ways to improve the lives of people affected by crises. This year, we achieved this in some of the following major ways:" />

        <h2>Informing policymakers: driving attention to the challenges of forcibly displaced people</h2>

        <InlineList>
          <p>Our reporting on the <Link href="https://www.thenewhumanitarian.org/opinion/first-person/2023/08/28/flipping-narrative-what-food-ration-cuts-mean-rohingya-refugees">impact of food ration cuts on Rohingya refugees</Link> prompted the UK Parliament’s International Development Committee to <strong>write to the WFP expressing concern</strong> about the situation of Rohingya refugees living in Bangladesh. The UN agency responded to the concerns raised by the chair, <strong>citing TNH’s reporting on the issue</strong>.</p>
          <p>Our reporting on <Link href="https://www.thenewhumanitarian.org/news-feature/2022/12/15/Nigeria-Boko-Haram-conflict-displacement-camps-Maiduguri-returns">camp closures</Link> in northern Nigeria helped trigger the United States Agency for International Development (USAID) to provide a <strong>$2 million grant to a non-governmental organisation (NGO) to help returning refugees</strong>.</p>
          <p>Our reporting on the <Link href="https://www.thenewhumanitarian.org/investigations/2023/07/03/exclusive-civilians-targeted-nigerias-war-boko">targeting of civilians in Nigeria’s war on Boko Haram</Link> helped the humanitarian community — including the European Commission — understand the credibility of the allegations and the egregiousness of the abuses.</p>
        </InlineList>

        <InlineQuote person={'Simba Tirima, head of Doctors Without Borders in Nigeria.'} text={'“Excellent work - the humanitarian world is at a loss for words,”'} />

        <h2>Providing transparency and accountability: prompting UN reassessment of carbon off-setting schemes</h2>

        <InlineList>
          <p>Our <Link href="https://www.thenewhumanitarian.org/investigations/2023/09/13/is-the-un-really-climate-neutral-climate-experts-say-no">investigation into UN carbon off-setting</Link> in collaboration with <Link href="https://news.mongabay.com/">Mongabay</Link> revealed that more than a dozen of the projects that issued the UN’s carbon credits were linked to reports of environmental damage, displacement, or health concerns. We also published a <Link href="https://www.thenewhumanitarian.org/opinion/2023/10/18/united-nations-carbon-offsetting-project-poisoning-okhla-waste-energy-plant">first-hand account</Link> of what it feels like to live near one of the UN-financed offset projects in India and a <Link href="https://www.thenewhumanitarian.org/news/2023/12/21/un-defends-questionable-offsetting-practices-new-emissions-report">follow-up report</Link> addressing UN inaction. The investigation prompted <strong>internal discussions</strong> at the UN, <strong>a re-assessment</strong> of one UN agency’s carbon offsetting schemes, and a <strong>public statement</strong> acknowledging the report.</p>
        </InlineList>

        <InlineQuote person={'Dr. David Boyd, the UN Special Rapporteur on Human Rights and Environment.'} text={'“It is a really well researched and well-written article. Congratulations and thank you for digging into this problem.”'} />

        <InlineImage fileName={'our-impact--inline.jpg'} caption={'Sukhdev Vihar residents protest against the Okhla waste-to-energy plant in March 2019. (Ranjit Devraj/TNH)'} />

        <h2>Raising awareness: balancing the narrative on Gaza</h2>

        <InlineList>
          <p>Just over two weeks into the renewed bombardment of Gaza, we <Link href="https://www.thenewhumanitarian.org/editorial/2023/10/23/media-coverage-israel-and-gaza-double-standards">published an editorial</Link> that highlights how the media coverage of Israel and Gaza is rife with double standards, and went on to report on the violent conditions in which <Link href="https://www.thenewhumanitarian.org/opinion/first-person/2023/12/20/being-a-journalist-in-gaza">Gaza’s journalists operate</Link> while highlighting often overlooked historical context. Several funders along with the UK Parliament’s International Development Committee, Sarah Champion, thanked us for <strong>injecting more nuance and balance</strong> into our coverage. We also used our reporting on the war to prepare <strong>a white paper informing UK Members of Parliament</strong> about the current situation in Gaza.</p>
        </InlineList>

        <InlineQuote person={'Mehra Rimer, co-founder and executive director of the B8 of Hope non-profit.'} text={'“When the media amplifies the voices of peacemakers over those of warmongers, people will start to change their beliefs and behaviours. Unfortunately, it seems that the media prefers conflict to dialogue and courageous solutions. Nonetheless, I have observed a slight shift in this trend,”'} />

        <h2>Providing a platform for the affected: new voices on humanitarian policy</h2>

        <InlineList>
          <p>Our new series, <Link href="https://www.thenewhumanitarian.org/tags/flipping-narrative">Flipping the Narrative</Link>, provides a platform for refugees, asylum seekers, and migrants to address the policies and events that shape their lives. The series was featured in an <Link href="https://pages.devex.com/better-conversations.html">ethical storytelling guide</Link> and one of the pieces, <Link href="https://www.thenewhumanitarian.org/opinion/first-person/2023/08/28/flipping-narrative-what-food-ration-cuts-mean-rohingya-refugees">What food ration cuts mean for Rohingya refugees</Link>, prompted the Chair of the UK International Development Committee, Sarah Champion, to write to the World Food Programme voicing her concerns and <strong>demanding a response</strong> from the humanitarian aid provider.</p>
        </InlineList>

        <InlineQuote person='Roberto Forin, Deputy Director, Mixed Migration Centre Europe, after attending the TNH-hosted event on refugee leadership.' text="“As a middle-aged white man who has worked in the humanitarian sector for almost 20 years, that event made me very uncomfortable. It took all my beliefs and really challenged them. Thank you.”" />
      </div>
    ),
  },
  {
    title: 'Connecting with our audience',
    id: 'connecting-with-our-audience',
    backgroundImage: '06-connecting-with-our-audience',
    className: 'bg-gray-300',
    htmlContent: (
      <ChapterHeader textShadow title={'Connecting with our audience'} subtitle={'Building better journalism.'} quote={['Building better journalism.']} />
    ),
    fullContent: (
      <>
        <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
          <InlineTypist text={['Building better journalism.']} />

          <h2 className={'text-center w-full'}>Audience and Reach</h2>

          <MarginBreakContainer>
            <div className={'gap-x-10 gap-y-16 grid grid-cols-1 sm:grid-cols-3 justify-center items-start sm:mx-0 md:-mx-10 lg:-mx-56 xl:-mx-80'}>
              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Eyes />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>2,100,000</span> total visitors in 2023
                </h3>
              </div>

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Eyes />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>175,182</span> average monthly users
                </h3>
              </div>

              {/* <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.StopWatch />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  3.34 min. average time spent reading an article
                </h3>
                <p className={'m-0'}>(as compared to industry standard of 1.82 min. for top 50 US newspapers)</p>
              </div> */}

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Email />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>71,062</span> newsletter subscribers
                </h3>
              </div>

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.SocialMedia />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>269,562</span> social media followers
                </h3>
              </div>

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Group />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>469</span> members
                </h3>
              </div>

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Newspaper />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>14 publishers</span> republicated our work.
                </h3>
              </div>

              <div className={'flex items-center justify-center flex-col text-center'}>
                <div className={'text-burgundy w-32'}>
                  <Icons.Podcast />
                </div>
                <h3 className={'text-3xl sm:text-5xl'} style={{ marginTop: '2rem' }}>
                  <span className={'font-bold font-sans block text-burgundy'}>162,857</span> podcast downloads
                </h3>
              </div>
            </div>
          </MarginBreakContainer>

          <p>TNH reaches policymakers and practitioners in humanitarian response and related fields; other media outlets; researchers and academics; and wider audiences in both Western capitals and crisis-affected communities. Our work reaches decision-makers, funders, and practitioners in humanitarian response, including those in UN, international and multilateral organisations, governments, local, national, and international NGOs, as well as the philanthropy and private sector. </p>

          <p>According to a 2022 audience survey</p>

          <InlineList>
            <p><strong>52 percent</strong> are senior professionals or executive management/C-suite level,</p>
            <p><strong>25 percent</strong> are mid-career</p>
            <p><strong>57 percent</strong> of respondents said their work has a major or moderate impact on humanitarian action and policy</p>
            <p><strong>39 percent</strong> have final or significant decision-making authority on humanitarian action within their organisation or institution</p>
            <p><strong>45 percent</strong> have some decision-making authority or influence</p>
          </InlineList>

          <p>TNH continued to have a significant presence on the global stage in 2023, with TNH staff speaking at more than 40 events, forums, and conferences around the world, from Geneva to Johannesburg and New York to Nairobi. These events allow us to bring our reporting and critical eye to new audiences around the world.
          </p>

          <InlineList>
            <p>Our Director of External Relations Sarah Noble gave a talk at TEDx Geneva on the power of storytelling.</p>
            <p>Middle East Editor Annie Slemrod and contributor Zainab Chamoun hosted a session on storytelling from the heart of crises, using their interactive story Whatsapp, Lebanon as a case study, at the DW Global Media Forum.</p>
            <p>Patrick Gathara, Senior Editor for Inclusive Storytelling, gave the keynote speech at the Humanitarian Congress Berlin.</p>
            <p>Jacob Goldberg, our investigative reporter, was part of a conference session on conducting joint investigative projects at N3con in Singapore.</p>
            <p>Our new Policy Editor Will Worley moderated a conversation on protecting civilians against digital threats during armed conflict at the International Committee of the Red Cross</p>
            <p>Senior Africa Editor Obi Anyadike spoke about covering insurgency at the African Investigative Journalism Conference.</p>
            <p>Asia Editor Ali Latifi discussed whether China can learn to live with the Taliban in an online conversation organised by Radio Free Europe.</p>
          </InlineList>

          <p>In 2023, we put all of our content under a Creative Commons licence, or Steal our Work Initiative, with guidelines on how to do so, allowing other media to republish any articles with just a couple clicks and empowering individuals and organisations to freely and easily engage with our work, amplifying our reach across diverse communities. This new tool led to republications of <strong>more than 100</strong> of our stories by various outlets. To further facilitate pick-up by other media, in 2023 we translated <strong>more than 40</strong> stories into different languages.</p>

          <InlineQuote person={'Editor in Chief, ICRC Policy Blog'} text={'“I wanted first and foremost to write and tell you: great work on the Steal our work initiative! I love the spirit of it and prioritising truth over ego/branding. Well done! My team and I are inspired to follow suit with the ICRC Humanitarian Law and Policy blog.”'} />

          <div className={'mx-auto'}>
            <h2>Where TNH stories traveled</h2>
            <InlineImage fileName={'our-audience-map.jpg'} wide />
          </div>

        </div>
      </>
    ),
  },
  {
    title: 'Funding and governance',
    id: 'funding-and-governance',
    backgroundImage: '07-funding-and-governance',
    className: 'bg-gray-200',
    htmlContent: (
      <ChapterHeader
        textShadow
        title={'Funding and governance'}
        subtitle={'The foundations that position us for success'}
        quote={['The foundations that position us for success']}
      />
    ),
    fullContent: (
      <div className={'px-5 flow flex flex-col justify-center items-start max-w-6xl mx-auto sm:pr-28'}>
        <InlineTypist text={['The foundations that position us for success']} />

        <h2>Our Finances</h2>

        <p>Since 2021, our financial statements have been prepared in accordance with the Swiss GAAP FER 21 accounting standards and, in 2022, our financial statements were subject to a full and independent audit by Berney Associés of Geneva in accordance with the Swiss NA-CH 700 auditing standards. In 2023, we have further upgraded the auditing standards applied by our auditors from the local Swiss NA-CH 700 full audit standards to the international ISA 700 audit standards to meet the wishes of some of our donors. Our 2023 financial statements and the report of the auditors can be found in Annex 1.</p>

        <p>Significant portions of our income are in the form of grants or donations intended for projects or expenses of more than one calendar year. Thus, we started 2023 with funds carried over from 2022 of CHF 1,726,020. The income received and recognised in 2023, some of which was intended for later years, amounted to CHF 3,061,755, compared with CHF 3,421,958 in 2022. At the end of 2023, we carried over CHF 1,141,874 into 2024.</p>

        <p>Almost 74 percent of our restricted income in 2023 was in the form of unearmarked grants for our general core operations, with the remainder earmarked for specific core operations or in support of additional projects that are aligned with our core mission and objectives.</p>

        <p>Our expenditure, including net financial expense, increased from CHF 3,003,031 in 2023 to CHF 3,553,873 in 2024. This expansion of our operations, which included 10 new hires, was made possible by the financial commitments received in 2022 for 2023.</p>

        <p>Our income includes individual donations and self-generated income, such as speaking fees and other paid services. From 2022 to 2023, individual donations increased from CHF 2,944 to CHF 16,611 and self-generated income rose from CHF 2,615 to CHF 12,480. However, our membership income experienced a slight decline from CHF 51,639 to CHF 48,519.</p>

        <p>The 2023 income and expenditure result in a final surplus for the year of CHF 92,028, being our unrestricted income minus certain expenditure, such as foreign exchange losses and bank fees, not eligible to be set against our restricted income. This surplus increased our free capital to CHF 586,000 after seven years of operations as an independent entity.</p>

        <h2>Staff and operations</h2>

        <p>As a newsroom, our IT infrastructure provides the backbone through which we can bring the kinds of engaging and critical reporting our audiences want to see. This year, we partnered with a new IT provider, InterHyve, to identify solutions and potential applications to further automate and streamline our workflow. We have improved our digital security and protection and are making strides towards TNH-owned IT equipment in the future. </p>

        <p>We improved the streamlining of policies for our staff’s per diem and travel, facilitating our administrative work and ensuring that staff take advantage of the services at their disposal.</p>

        <p>The hiring of Executive Coordinator Yasmine Kergoat increased our capacity to monitor progress against our strategy more systematically, track management decisions, and communicate key developments to the staff.</p>


      </div>
    ),
  },
]

export default CHAPTERS
