import React, { useState, useCallback } from 'react'

import { graphql } from 'gatsby'
import debounce from 'lodash.debounce'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '@components/common/layout'
import FullScreenHeader from '@components/scrollytelling/annual-report/full-screen-header'
import FullHeightSlide from '@components/scrollytelling/reports/full-height-slide'
import TopNav from '@components/scrollytelling/reports/top-navigation'

// Import data
import CHAPTERS from '@data/annual-report-2023/CHAPTERS'

const AnnualReport2022Story = (props) => {
  const [currentChapter, setCurrentChapter] = useState('welcome')

  const Images = props.data.allFile.edges

  const changeChapter = (newChapter) => {
    setCurrentChapter(newChapter)
  }

  const debouncedchangeChapter = useCallback(debounce(changeChapter, 50), [])

  const getImageByName = useCallback(
    (name) => {
      // Wrap function in try catch block to prevent build error
      try {
        return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
      } catch (e) {
        console.log(e, name)
        return null
      }
    },
    [Images]
  )

  return (
    <Layout
      bgColor={'bg-white'}
      shareProps={{
        title: 'The New Humanitarian | Annual Report 2023', // TODO
        url: 'https://interactive.thenewhumanitarian.org/reports/2024/07/25/annual-report-2023/', // TODO
        socialTitle: 'The New Humanitarian | Annual Report 2023', // TODO
        socialDescription: 'Our journalism, impact, audience, and more.', // TODO
        socialImage: 'https://interactive.thenewhumanitarian.org/images/reports/annual-report-2023/annual-report-2023--teaser.png',
        nodeId: null,
        updatedTime: '2023-07-25T15:00:00+00:00',
        modiefiedTime: '2023-07-25T15:00:00+00:00',
        publishedTime: '2023-07-25T15:00:00+00:00',
      }}
    >
      <FullScreenHeader
        title={['Journalism from the heart of crises']}
        subtitle={'The New Humanitarian Annual Report 2023'}
        image={getImageByName('00-cover-photo')}
        downloads={[
          {
            text: 'Annual Report 2023',
            url: 'https://assets.thenewhumanitarian.org/s3fs-public/2024-07/TNH%20Annual%20Report%202023.pdf',
          },
          {
            text: 'Executive Summary',
            url: 'https://assets.thenewhumanitarian.org/s3fs-public/2024-07/TNH%20Annual%20Report%202023%20Executive%20Summary.pdf',
          },
        ]}
      ></FullScreenHeader>

      <div id='start' />

      <TopNav chapters={CHAPTERS} currentChapter={currentChapter} />
      {CHAPTERS.map((el) => {
        return (
          <FullHeightSlide chapters={CHAPTERS} className={`overflow-x-hidden ${el.className}`} chapter={el.id} inViewHandler={debouncedchangeChapter}>
            {el.backgroundImage && (
              <GatsbyImage
                alt={''}
                image={getImageByName(el.backgroundImage)}
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                imgStyle={{ objectFit: 'cover' }}
                className={'z-0'}
              />
            )}
            {el.htmlContent ? <div className={'z-0'}>{el.htmlContent}</div> : <h2 className={'text-burgundy bg-white px-5 py-3'}>{el.title}</h2>}
          </FullHeightSlide>
        )
      })}
    </Layout>
  )
}

export default AnnualReport2022Story

export const query = graphql`
	query AnnualReport2023Images {
		allFile(
			filter: { sourceInstanceName: { eq: "images" }, extension: { in: ["jpg", "png", "jpeg"] }, relativePath: { glob: "reports/annual-report-2023/*" } }
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(
							placeholder: BLURRED
							width: 1920
							quality: 85
							layout: FULL_WIDTH
						)
					}
				}
			}
		}
	}
`
